@keyframes pulsate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes flyHearts {
  0% {
    transform: translateY(0) scale(0.5);
    opacity: 1;
  }
  100% {
    transform: translateY(-1500px) scale(0.1);
    opacity: 0;
  }
}

@keyframes slide-left {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

body {
  transition: background-color 0.5s ease;
}

.App {
  text-align: center;
  background: #ecebe7;
  background-size: 400% 400%;
  animation: pulsate 15s ease infinite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  position: relative;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.RadioPlayer {
  background: #000000;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 350px;
  max-width: 90%;
  text-align: center;
  color: white;
  position: relative;
  align-items: center;
  justify-content: center;
}

.RadioPlayer-display {
  display: none; /* Hide the display area */
}

.RadioPlayer-button {
  padding: 10px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RadioPlayer-button:hover {
  background-color: #ccc;
}

.RadioPlayer-volume {
  width: 80%;
  margin: 10px 0;
}

.RadioPlayer-stations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.RadioPlayer-station-name {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 40px;
}
.RadioPlayer-current-song {
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;
  text-wrap: nowrap;
  width: 120%;
  position: absolute;
  animation: slide-left 10s linear infinite;
}

.RadioPlayer-station-button {
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 20px;
}

.RadioPlayer-station-button:hover {
  color: #ccc;
}

.RadioPlayer-albumArt {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.RadioPlayer-station-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the station cards */
  margin-top: 20px;
  gap: 10px;
}

.RadioPlayer-station-card {
  background: none; /* Remove background */
  padding: 0; /* Remove padding */
  width: 120px; /* Keep the card square */
  aspect-ratio: 1; /* Keep the card square */
  flex-direction: unset; /* Remove column direction */
  justify-content: unset; /* Remove center alignment */
  align-items: unset; /* Remove center alignment */
  cursor: pointer;
  transition: transform 0.3s ease, scale 0.4s ease; /* Add transition for smooth effect */
  text-align: center;
}

.RadioPlayer-station-card:hover {
  transform: translateY(-10px); /* Raise the card on hover */
}

.RadioPlayer-station-card-active {
  scale: 1.05; /* Raise the card on hover */
}

.RadioPlayer-station-card-image {
  width: 100%; /* Make image fill the card */
  border-radius: 10px; /* Keep border radius */
  margin: 0; /* Remove margin */
  object-fit: cover; /* Ensure image covers the card */
  aspect-ratio: 1; /* Keep the image square */
}

.heart {
  position: absolute;
  width: 512px;
  height: 512px;
  background-color: rgb(188, 32, 32);
  clip-path: path("M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z");
  animation: flyHearts 1s ease-out forwards;
}

.audio-visualizer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
